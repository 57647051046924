import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useNavigate } from "react-router-dom";
import { IArticleCriteria, IArticleView } from "../Services/ArticlesService";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { sortOptions } from "../../../constants/sortOptions";
import { ISortBy } from "../../Gods/Services/GodsService";
interface ArticleListViewProps {
  getAll: () => void;
  articles: IArticleView;
  searchByCiteria: (criteria: IArticleCriteria) => void;
  onUpdateIsActive: (id: string, isActive: boolean) => void;
}

const ArticleListView: FC<ArticleListViewProps> = (props) => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isActive, setIsActive] = useState(true);
  const [sortBy, setSortBy] = useState<ISortBy>(sortOptions[0]);
  const [writter, setWritter] = useState("");
  const searchByCiteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IArticleCriteria = {
      title: title,
      content: content,
      isActive: isActive,
      page: pageNumber,
      limit: rowLimit ?? 10,
      sortBy: sortBy,
      writter: writter
    };
    props.searchByCiteria(criteria);
  };

  const truncate = (input: string) =>
    input.length > 20 ? `${input.substring(0, 20)}...` : input;

  return (
    <Box component={"div"}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>บทความ</Typography>
        <Typography variant="h6" color="text.primary">
          รายการ
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="หัวข้อ"
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="บทความ"
                        onChange={(e) => {
                          setContent(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="ผู้เขียน"
                        onChange={(e) => {
                          setWritter(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={2.5}>
                      <FormControl fullWidth>
                        <InputLabel id="status-select-label">เรียงตาม</InputLabel>
                        <Select
                          labelId="status-select-label"
                          id="status-select"
                          value={sortBy.value}
                          label="เรียงตาม"
                          onChange={(event) => {
                            const selectedOption = sortOptions.find(option => option.value === event.target.value);
                            if (selectedOption) {
                              setSortBy(selectedOption);
                            }
                          }}
                        >
                          {sortOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>

                      </FormControl>
                    </Grid>
                    <Grid item xs={1.5} textAlign={"center"} alignSelf={"center"}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isActive}
                              onChange={(e) => setIsActive(e.target.checked)}
                            />
                          }
                          label="ใช้งานอยู่"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} textAlign={"center"} alignSelf={"end"}>
                  <Button
                    variant="contained"
                    onClick={() => searchByCiteria(0)}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography>
                        ทั้งหมด {props.articles.total} รายการ
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      alignContent={"flex-end"}
                      textAlign={"end"}
                    >
                      <Button
                        variant="contained"
                        startIcon={<NoteAddIcon />}
                        onClick={() => navigate("/article-create")}
                      >
                        สร้าง
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">หัวข้อ</TableCell>
                          <TableCell align="center">บทความ</TableCell>
                          <TableCell align="center">ผู้เขียน</TableCell>
                          <TableCell align="center">บันทึก</TableCell>
                          <TableCell align="center">แก้ไข</TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.articles.datas &&
                          props.articles.datas.length > 0 ? (
                          props.articles.datas.map((article, index) => (
                            <TableRow
                              key={article.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">{article.title}</TableCell>
                              <TableCell align="center"
                                dangerouslySetInnerHTML={{
                                  __html: truncate(article.content ?? ""),
                                }}
                              ></TableCell>
                              <TableCell align="center"
                                dangerouslySetInnerHTML={{
                                  __html: truncate(article.writter ?? ""),
                                }}
                              ></TableCell>
                              <TableCell align="center">
                                <Typography fontWeight={"bold"}>
                                  {article.createBy
                                    ? article.createBy.firstName
                                    : ""}
                                </Typography>
                                <Typography>
                                  {article.createAt
                                    ? dayjs(article.createAt).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                    : ""}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography fontWeight={"bold"}>
                                  {article.updateBy
                                    ? article.updateBy.firstName
                                    : ""}
                                </Typography>
                                <Typography>
                                  {article.updateAt
                                    ? dayjs(article.updateAt).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                    : ""}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Switch
                                  checked={article.isActive}
                                  onChange={() => {
                                    props.onUpdateIsActive(
                                      article.id!,
                                      !article.isActive
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    navigate(`/article-edit/${article.id}`)
                                  }
                                >
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              ไม่พบข้อมูล
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={props.articles.total ?? 0}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        searchByCiteria(newPage);
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setLimit(e.target.value);
                        setPage(0);
                        searchByCiteria(page, e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ArticleListView;
