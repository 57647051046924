import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { ISystemUserViewData, IUser } from "../../Settings/Services/SystemUsersService";
import { ISortBy } from "../../Gods/Services/GodsService";

export interface IArticle {
    id?: string;
    title?: string;
    content?: string;
    image?: string;
    createAt?: Date;
    updateAt?: Date;
    isDelete?: boolean;
    isActive?: boolean;
    createBy?: string;
    updateBy?: string;
    articleDetails?: IArticleDetail[];
    writter?: string;
}

export interface IArticleDetail {
    locale: string;
    title: string;
    content: string;
}

export interface IArticleViewData {
    id?: string;
    title?: string;
    content?: string;
    image?: string;
    createAt: Date;
    updateAt: Date;
    isDelete?: boolean;
    isActive?: boolean;
    createBy?: IUser;
    updateBy?: IUser;
    writter?: string;
}
export interface IUploadFile {
    file: any;
}

export interface IArticleCriteria {
    title: string;
    content: string;
    sortBy?: ISortBy;
    isActive?: boolean;
    page: number;
    limit: number;
    writter?: string;
}


export interface IArticleView {
    datas: IArticleViewData[];
    all: number;
    limit: number;
    page: number;
    total: number;
}

export interface IArticleUpdateIsactiveChecked {
    isActive: boolean;
    updateAt: Date;
}

class ArticlesService {
    articles: IArticleView = {} as IArticleView;
    user: ISystemUserViewData | null = null;
    constructor() {
        makeAutoObservable(this);
        this.user = JSON.parse(localStorage.getItem("user") || '{}');
    }

    async getAll() {
        try {
            const response = await apiService.get("/articles");
            if (response.length > 0) {
                this.articles = {
                    datas: response.filter((x: IArticle) => !x.isDelete),
                    all: response.length,
                    limit: 10,
                    page: 1,
                    total: response.length
                };
            }
            return this.articles;
        } catch (error) {
            throw error;
        }
    }

    async getOne(id: string) {
        try {
            const response = await apiService.get(`/articles/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getByCriteria(criteria: IArticleCriteria) {
        try {
            const response = await apiService.post("/articles/criteria", criteria);
            this.articles = {
                datas: response.data.filter((x: IArticle) => !x.isDelete),
                all: response.length,
                limit: response.limit,
                page: response.page,
                total: response.total
            };
            return this.articles;
        } catch (error) {
            throw error;
        }
    }

    async create(data: IArticle, uploadFile: IUploadFile) {
        try {
            let response = '';
            await apiService.post("/articles", data).then(async (result) => {
                const formData = new FormData();
                formData.append('file', uploadFile.file);
                formData.append('refId', result.id);
                formData.append('refType', 'article');
                formData.append('createBy', data.createBy || this.user?.id || 'admin');
                formData.append('createAt', new Date(Date.now()).toISOString());
                await apiService.postFormData("/images/upload", formData).then((res) => {
                    if (res) {
                        response = res;
                    }
                });
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    async update(id: string, data: IArticle) {
        try {
            const response = await apiService.put(`/articles/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async uploadImage(id: string, uploadFile: IUploadFile) {
        try {
            const formData = new FormData();
            formData.append('file', uploadFile.file);
            formData.append('refId', id);
            formData.append('refType', 'article');
            formData.append('createBy', this.user?.id || 'admin');
            formData.append('createAt', new Date(Date.now()).toISOString());
            const response = await apiService.postFormData("/images/upload", formData);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async deleteArticle(id: string) {
        try {
            const data = { isDelete: true };
            const response = await apiService.put(`/articles/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async updateIsActiveChecked(id: string, data: IArticleUpdateIsactiveChecked) {
        try {
            const response = await apiService.put(`/articles/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

const articlesService = new ArticlesService();
export default articlesService;