import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
  TextField,
  styled,
  Stack,
  ButtonProps,
  Divider,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tab,
} from "@mui/material";
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { IArticle, IUploadFile } from "../Services/ArticlesService";
import JoditEditor from "jodit-react";
import DialogConfirm from "../../../components/DialogConfirm";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import JoditConfig from "../../../constants/joditConfig";
import { checkValidation } from "../../../utils";
import loginService from "../../Login/Services/LoginService";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import langs from "../../../constants/langs";

interface ArticleCreateViewProps {
  getOne: (id: string) => void;
  onCreate: (data: IArticle, uploadFile: IUploadFile) => void;
  onUpdate: (id: string, data: IArticle, uploadFile: IUploadFile) => void;
  onDelete: (id: string) => void;
  data: IArticle;
  articleId: string;
  previousImage: any;
}

const ArticleCreateView: FC<ArticleCreateViewProps> = (props) => {
  const navigate = useNavigate();
  const user = loginService.user;

  const initialTitleNames = useMemo(() =>
    Object.fromEntries(langs.map(lang => [lang.value, ""]))
    , []);

  const initialContents = useMemo(() =>
    Object.fromEntries(langs.map(lang => [lang.value, ""]))
    , []);

  const [titleNames, setTitleNames] = useState<{ [key: string]: string; }>(initialTitleNames);
  const [contents, setContents] = useState<{ [key: string]: string; }>(initialContents);

  const [isActive, setIsActive] = useState(true);

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  const [selectedFile, setSelectedFile] = useState<any>();
  const [writter, setWritter] = useState("");

  useEffect(() => {
    if (props.data && props.data.articleDetails) {
      setIsActive(props.data.isActive ?? true);
      const titles: { [key: string]: string; } = {};
      const conts: { [key: string]: string; } = {};
      props.data.articleDetails.forEach(detail => {
        titles[detail.locale] = detail.title;
        conts[detail.locale] = detail.content;
      });
      setTitleNames(titles);
      setContents(conts);
      setWritter(props.data?.writter ?? "");
    }
  }, [props.data]);

  const handleTitleChange = (value: string, locale: string) => {
    setTitleNames(prevState => ({
      ...prevState,
      [locale]: value,
    }));
  };

  const handleContentChange = (value: string, locale: string) => {
    setContents(prevState => ({
      ...prevState,
      [locale]: value,
    }));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleSave = () => {
    if (checkValidate()) {
      const articleDetails = langs.map(lang => ({
        locale: lang.value,
        title: titleNames[lang.value],
        content: contents[lang.value],
      }));

      props.onCreate(
        {
          title: "",
          content: "",
          isActive: isActive,
          createAt: new Date(),
          createBy: user?.id ?? "",
          articleDetails: articleDetails,
          writter: writter
        },
        { file: selectedFile }
      );
    }
  };

  const handleEdit = () => {
    if (checkValidate()) {
      const articleDetails = langs.map(lang => ({
        locale: lang.value,
        title: titleNames[lang.value],
        content: contents[lang.value],
      }));
      props.onUpdate(
        props.articleId ?? "",
        {
          title: "",
          content: "",
          isActive: isActive,
          updateAt: new Date(),
          updateBy: user?.id ?? "",
          articleDetails: articleDetails,
          writter: writter
        },
        selectedFile
      );
    }
  };

  const handleDelete = () => {
    props.onDelete(props.articleId ??
      "",);
  };

  const [value, setValue] = React.useState("th");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const checkValidate = () => {
    const convertContentHTMLToString = contents[value].replace(/<[^>]+>/g, "");
    if (!checkValidation(titleNames[value], "กรุณากรอกหัวเรื่อง")) return false;
    if (!checkValidation(convertContentHTMLToString, "กรุณากรอกบทความ")) return false;
    if (!selectedFile && !props.previousImage) {
      if (!checkValidation(selectedFile, "กรุณาเลือกไฟล์")) return false;
    }
    return true;
  };

  const config = JoditConfig;


  const editor = useMemo(
    () => (
      <JoditEditor
        config={config}
        value={contents[value]}
        onBlur={(newContent: string) => handleContentChange(newContent, value)}
        className="jodit_toolbar_btn-text"

      />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contents, value]
  );
  return (
    <Box component={"div"}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/articles-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>บทความ</Typography>
            <Link component="button" onClick={() => navigate("/articles-list")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.articleId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {props.articleId && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setOpenConfirm({
                  open: true,
                  title: "ลบบทความ",
                  message: "คุณต้องการลบบทความนี้ใช่หรือไม่",
                  color: "error",
                  type: "delete",
                });
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} mt={1}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    fontWeight={"bold"}
                    fontSize={16}
                  >
                    บทความ
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList onChange={handleChange}>
                        {langs.map(lang => (
                          <Tab key={lang.value} value={lang.value} label={lang.name} />
                        ))}
                      </TabList>
                    </Box>
                    {langs.map((lang) => (
                      <TabPanel

                        key={lang.value} value={lang.value}
                      >
                        <Grid item xs={12} mb={2}>
                          <TextField
                            id="title"
                            label="หัวเรื่อง"
                            variant="outlined"
                            fullWidth
                            value={titleNames[lang.value]}
                            onChange={(e) => handleTitleChange(e.target.value, lang.value)}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          {editor}
                        </Grid>
                      </TabPanel>
                    ))}
                  </TabContext>
                </Grid>

                <Grid item xs={12} mr={3} ml={3}>
                  <TextField
                    fullWidth
                    label="ผู้เขียน"
                    value={writter}
                    onChange={(e) => {
                      setWritter(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} mt={1}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    fontWeight={"bold"}
                    fontSize={16}
                  >
                    ภาพปก
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                  >
                    เลือกไฟล์
                    <VisuallyHiddenInput
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleFileChange}
                    />
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  {selectedFile && (
                    <Box>
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt="Selected"
                        style={{ maxWidth: 300, height: "auto" }}
                      />
                    </Box>
                  )}
                  {props.data.image && !selectedFile ? (
                    <Box>
                      <img
                        src={`${process.env.REACT_APP_API_URL}/uploads/${(props.data.image as any).name
                          }`}
                        alt="Selected"
                        style={{ maxWidth: 300, height: "auto" }}
                      />
                    </Box>
                  ) : (
                    []
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={8}>
                  {
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isActive}
                            onChange={(e) => setIsActive(e.target.checked)}
                          />
                        }
                        label="ใช้งาน"
                      />
                    </FormGroup>
                  }
                </Grid>

                <Grid item xs={8}></Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={() => navigate("/articles-list")}
                        >
                          ยกเลิก
                        </Button>
                        {
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              if (props.articleId) {
                                setOpenConfirm({
                                  open: true,
                                  title: "แก้ไขบทความ",
                                  message: "คุณต้องการแก้ไขบทความนี้ใช่หรือไม่",
                                  color: "primary",
                                  type: "edit",
                                });
                              } else {
                                setOpenConfirm({
                                  open: true,
                                  title: "บันทึกบทความ",
                                  message:
                                    "คุณต้องการบันทึกบทความนี้ใช่หรือไม่",
                                  color: "primary",
                                  type: "create",
                                });
                              }
                            }}
                          >
                            บันทึก
                          </Button>
                        }
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            handleDelete();
          } else if (openConfirm.type === "create") {
            handleSave();
          } else if (openConfirm.type === "edit") {
            handleEdit();
          }
        }}
      />
    </Box>
  );
};

export default ArticleCreateView;
