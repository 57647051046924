import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
  ButtonProps,
  Tab,
} from "@mui/material";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import JoditEditor from "jodit-react";
import { Clear } from "@mui/icons-material";
import styled from "@emotion/styled";
import { IGod, IUploadFile, ShowImages } from "../Services/GodsService";
import DialogConfirm from "../../../components/DialogConfirm";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import JoditConfig from "../../../constants/joditConfig";
import { checkValidation } from "../../../utils";
import loginService from "../../Login/Services/LoginService";
import langs from "../../../constants/langs";
import { TabContext, TabList, TabPanel } from "@mui/lab";

interface GodCreateViewProps {
  getOne: (id: number) => void;
  onCreate: (data: IGod, uploadFile: IUploadFile) => void;
  onUpdate: (
    id: number,
    data: IGod,
    godImage: IUploadFile,
    wallPapers: IUploadFile[]
  ) => void;
  onDelete: (id: number) => void;
  data: IGod;
  godId: string;
  removeWallpaper: (index: number) => void;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const GodCreateView: FC<GodCreateViewProps> = (props) => {
  const navigate = useNavigate();
  const user = loginService.user;

  const initialTiTle = useMemo(() => Object.fromEntries(langs.map(lang => [lang.value, ""])), []);
  const initialTiTlePremium = useMemo(() => Object.fromEntries(langs.map(lang => [lang.value, ""])), []);
  const initialDescription = useMemo(() => Object.fromEntries(langs.map(lang => [lang.value, ""])), []);
  const initialPrayer = useMemo(() => Object.fromEntries(langs.map(lang => [lang.value, ""])), []);
  const initialDescriptionPremium = useMemo(() => Object.fromEntries(langs.map(lang => [lang.value, ""])), []);

  const [title, setTitle] = useState<{ [key: string]: string; }>(initialTiTle);
  const [titlePremium, setTitlePremium] = useState<{ [key: string]: string; }>(initialTiTlePremium);
  const [description, setDescription] = useState<{ [key: string]: string; }>(initialDescription);
  const [prayer, setPrayer] = useState<{ [key: string]: string; }>(initialPrayer);
  const [descriptPremium, setDescriptPremium] = useState<{ [key: string]: string; }>(initialDescriptionPremium);
  const [isFavorite, setIsFavorite] = useState(false);
  const [selectedGodFile, setSelectedGodFile] = useState<any>();
  const [selectedWallpaperFileMulti, setSelectedWallpaperFileMulti] = useState<
    any[]
  >([]);


  const [value, setValue] = useState("th");
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  useEffect(() => {
    if (props.data && props.data.godDetails) {
      const titles: { [key: string]: string; } = {};
      const titlesPremium: { [key: string]: string; } = {};
      const descriptions: { [key: string]: string; } = {};
      const descriptionsPremium: { [key: string]: string; } = {};
      const prayer: { [key: string]: string; } = {};
      props.data.godDetails.forEach((detail) => {
        titles[detail.locale] = detail.title;
        titlesPremium[detail.locale] = detail.titlePremium;
        descriptions[detail.locale] = detail.description;
        descriptionsPremium[detail.locale] = detail.descriptPremium;
        prayer[detail.locale] = detail.prayer;
      });
      setTitle(titles);
      setDescription(descriptions);
      setPrayer(prayer);
      setTitlePremium(titlesPremium);
      setDescriptPremium(descriptionsPremium);
      setIsFavorite(props.data.isFavorite ?? false);
    }
  }, [props.data]);

  const handleTitleChange = (value: string, locale: string) => {
    setTitle(prevState => ({
      ...prevState,
      [locale]: value
    }));
  };

  const handleTitlePremiumChange = (value: string, locale: string) => {
    setTitlePremium(prevState => ({
      ...prevState,
      [locale]: value
    }));
  };

  const handleDescriptionChange = (value: string, locale: string) => {
    setDescription(prevState => ({
      ...prevState,
      [locale]: value
    }));
  };

 const handlePrayerChange = (value: string, locale: string) => {
    setPrayer(prevState => ({
      ...prevState,
      [locale]: value
    }));
  };

  const handleDescriptionPremiumChange = (value: string, locale: string) => {
    setDescriptPremium(prevState => ({
      ...prevState,
      [locale]: value
    }));
  };

  const handleGodFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setSelectedGodFile(e.target.files[0]);
    }
  };

  const handleWallpaperFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).slice(
        0,
        5 - selectedWallpaperFileMulti.length
      );
      setSelectedWallpaperFileMulti((prev) => [...prev, ...filesArray]);
    }
  };

  const handleRemoveFile = (index: number) => {
    setSelectedWallpaperFileMulti((prev) => prev.filter((_, i) => i !== index));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleSave = () => {
    if (checkValidate()) {
      const godDetails = langs.map((lang) => ({
        locale: lang.value,
        title: title[lang.value],
        titlePremium: titlePremium[lang.value],
        description: description[lang.value],
        descriptPremium: descriptPremium[lang.value],
        prayer: prayer[lang.value],
      }));
      props.onCreate(
        {
          title: "",
          description: "",
          prayer: "",
          titlePremium: "",
          descriptPremium: "",
          isFavorite: isFavorite,
          createAt: new Date(),
          createBy: user?.id ?? "",
          godDetails: godDetails,
        },
        {
          godFile: selectedGodFile,
          wallpaperFileMulti: selectedWallpaperFileMulti,
        }
      );
    }
  };

  const handleEdit = () => {
    if (checkValidate()) {
      const godDetails = langs.map((lang) => ({
        locale: lang.value,
        title: title[lang.value],
        titlePremium: titlePremium[lang.value],
        description: description[lang.value],
        descriptPremium: descriptPremium[lang.value],
        prayer: prayer[lang.value],
      }));
      props.onUpdate(
        +props.godId,
        {
          title: "",
          description: "",
          prayer: "",
          titlePremium: "",
          descriptPremium: "",
          isFavorite: isFavorite,
          updateBy: user?.id ?? "",
          updateAt: new Date(),
          godDetails: godDetails
        },
        selectedGodFile,
        selectedWallpaperFileMulti
      );
    }
  };

  const handleDelete = () => {
    props.onDelete(+props.godId);
  };

  const checkValidate = () => {
    const convertDescriptionHTMLToString = description[value]?.replace(/<[^>]+>/g, "");
    if (!checkValidation(title, "กรุณากรอกชื่อ")) return false;
    if (!checkValidation(convertDescriptionHTMLToString, "กรุณากรอกรายละเอียด"))
      return false;
    if (!selectedGodFile && !props.data.image) {
      if (!checkValidation(false, "กรุณาเลือกภาพปก")) return false;
    }
    if (
      selectedWallpaperFileMulti.length === 0 &&
      (!props.data.wallpaper || props.data.wallpaper.length === 0)
    ) {
      if (!checkValidation(false, "กรุณาเลือกภาพวอลเปเปอร์")) return false;
    }
    return true;
  };

  const config = JoditConfig;

  const editorGod = useMemo(
    () => (
      <JoditEditor
        config={config}
        value={description[value]}
        onBlur={(newContent: string) => handleDescriptionChange(newContent, value)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [description, value]
  );

  const editorGodPrayer = useMemo(
    () => (
      <JoditEditor
        config={config}
        value={prayer[value]}
        onBlur={(newContent: string) => handlePrayerChange(newContent, value)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prayer, value]
  );

  const editorGodPremium = useMemo(
    () => (
      <JoditEditor
        config={config}
        value={descriptPremium[value]}
        onBlur={(newContent: string) => handleDescriptionPremiumChange(newContent, value)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [descriptPremium, value]
  );

  return (
    <Box component={"div"}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/gods-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>เทพเจ้า</Typography>
            <Link component="button" onClick={() => navigate("/gods-list")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.godId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {props.godId && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setOpenConfirm({
                  open: true,
                  title: "ลบข้อมูล",
                  message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
                  color: "error",
                  type: "delete",
                });
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} mt={1}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    fontWeight={"bold"}
                    fontSize={16}
                  >
                    เทพเจ้า
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList onChange={handleChange}>
                        {langs.map(lang => (
                          <Tab key={lang.value} value={lang.value} label={lang.name} />
                        ))}
                      </TabList>
                    </Box>
                    {langs.map(lang => (
                      <TabPanel key={lang.value} value={lang.value}>
                        <Grid item xs={12} mb={2}>
                          <TextField
                            fullWidth
                            label="ชื่อ"
                            value={title[lang.value]}
                            onChange={(e) => {
                              handleTitleChange(e.target.value, lang.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} >
                          รายละเอียด
                        </Grid>
                        <Grid item xs={12} mb={2}>
                          {editorGod}
                        </Grid>
                        <Grid item xs={12} mt={2} mb={2}>
                          <Divider variant="middle" />
                        </Grid>
                        <Grid item xs={12}>
                          รายละเอียดคำขอพร
                        </Grid>
                        <Grid item xs={12}>
                          {editorGodPrayer}
                        </Grid>

                        <Grid item xs={12} mt={2} mb={2}>
                          <Divider variant="middle" />
                        </Grid>
                        <Grid item xs={12} mb={2}>
                          <TextField
                            fullWidth
                            label="ชื่อพรีเมี่ยม"
                            onChange={(e) => {
                              handleTitlePremiumChange(e.target.value, lang.value);
                            }}
                            value={titlePremium[lang.value]}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          รายละเอียดพรีเมี่ยม
                        </Grid>
                        <Grid item xs={12}>
                          {editorGodPremium}
                        </Grid>
                      </TabPanel>
                    ))}
                  </TabContext>

                </Grid>

                <Grid item xs={12} mt={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isFavorite}
                        onChange={(e) => {
                          setIsFavorite(e.target.checked);
                        }}
                      />
                    }
                    label="แสดงหน้าแรก"
                  />
                </Grid>

                <Grid item xs={12} mt={1}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    fontWeight={"bold"}
                    fontSize={16}
                  >
                    ภาพปก
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                  >
                    เลือกไฟล์
                    <VisuallyHiddenInput
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleGodFileChange}
                    />
                  </Button>
                </Grid>

                <Grid item xs={4}>
                  {selectedGodFile && (
                    <Box mt={1}>
                      <img
                        src={URL.createObjectURL(selectedGodFile)}
                        alt="Selected"
                        style={{ maxWidth: 300, maxHeight: "auto" }}
                      />
                    </Box>
                  )}
                  {props.data.image && !selectedGodFile ? (
                    <Box mt={1}>
                      <img
                        src={`${process.env.REACT_APP_API_URL}/uploads/${props.data.image.name}`}
                        alt="Selected"
                        style={{ maxWidth: 300, maxHeight: "auto" }}
                      />
                    </Box>
                  ) : (
                    []
                  )}
                </Grid>
                <Grid item xs={12} mt={1}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    fontWeight={"bold"}
                    fontSize={16}
                  >
                    ภาพวอลเปเปอร์
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                  >
                    เลือกไฟล์
                    <VisuallyHiddenInput
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleWallpaperFileChange}
                    />
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {props.data.wallpaper && props.data.wallpaper.length > 0
                      ? props.data.wallpaper.map(
                        (file: ShowImages, index: number) => (
                          <Grid item xs={2} key={index}>
                            <Box position="relative" mt={1}>
                              <img
                                src={`${process.env.REACT_APP_API_URL}/uploads/gods/${file.name}`}
                                alt="Selected"
                                style={{ maxWidth: 300, maxHeight: "auto" }}
                              />
                              <Button
                                size="small"
                                onClick={() => props.removeWallpaper(index)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  width: "1.5em",
                                  height: "1.5em",
                                  minWidth: "initial",
                                  padding: "1.5em", //
                                  color: "white",
                                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                                  borderRadius: "50%",
                                }}
                              >
                                <Clear />
                              </Button>
                            </Box>
                          </Grid>
                        )
                      )
                      : []}

                    {selectedWallpaperFileMulti.map((file, index) => (
                      <Grid item xs={2} key={index}>
                        <Box position="relative" mt={1}>
                          <img
                            src={URL.createObjectURL(file)}
                            alt="Selected"
                            style={{ maxWidth: 300, maxHeight: "auto" }}
                          />
                          <Button
                            size="small"
                            onClick={() => handleRemoveFile(index)}
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              width: "1.5em",
                              height: "1.5em",
                              minWidth: "initial",
                              padding: "1.5em", //
                              color: "white",
                              backgroundColor: "rgba(0, 0, 0, 0.6)",
                              borderRadius: "50%",
                            }}
                          >
                            <Clear />
                          </Button>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Divider />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="right"
                  alignItems="center"
                  mt={2}
                >
                  <Button
                    variant="outlined"
                    style={{ marginRight: "10px", width: "15%" }}
                    onClick={() => navigate("/gods-list")}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    variant="contained"
                    style={{ width: "15%" }}
                    onClick={() => {
                      if (props.godId) {
                        setOpenConfirm({
                          open: true,
                          title: "แก้ไขข้อมูล",
                          message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
                          color: "primary",
                          type: "edit",
                        });
                      } else {
                        setOpenConfirm({
                          open: true,
                          title: "บันทึกข้อมูล",
                          message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
                          color: "primary",
                          type: "create",
                        });
                      }
                    }}
                  >
                    บันทึก
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            handleDelete();
          } else if (openConfirm.type === "create") {
            handleSave();
          } else if (openConfirm.type === "edit") {
            handleEdit();
          }
        }}
      />
    </Box>
  );
};

export default GodCreateView;
